import axios from 'axios'
import { API_BASE_URL } from "../config"

export default new class {
    geolocate() {
        if (navigator.geolocation) {
            var positionOptions = {
                enableHighAccuracy: true,
                timeout: 10000, // 10 seconds
            };
            return new Promise((resolve, reject) => navigator.geolocation.getCurrentPosition(
                (pos) => resolve({ lat: pos.coords.latitude, lng: pos.coords.longitude }),
                (err) => reject(err),
                positionOptions
            ));
        }
        return true;
    }

    getLocationInfo(zip) {
        return axios.get(`${API_BASE_URL}/location?zip=${zip}`)
            .then((response) => response.data)
            .catch((err) => console.error(err))
    }

    getIncentivesByState(state) {
        return axios.get(`${API_BASE_URL}/incentives?state=${state}`)
            .then((response) => response.data)
            .catch((err) => console.error(err))
    }
}