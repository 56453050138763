import common from "Mixins/common";
import { Constants } from "Services/Constants";
import { MetricCalculations } from "Services/MetricCalculations";

export const FeatureMap = [
  {
    name: "CompareSummary",
    label: "",
    metrics: [
      {
        col: "Price",
        label: "Starting at",
        show: true,
        format: {
          name: "currency",
          decimals: 0,
        },
      },
      {
        col: "Rebate",
        label: "Federal tax credit",
        format: {
          name: "currency",
          decimals: 0,
        },
        show: true,
        defaultValue: "Not eligible",
      },
      { col: "Range", label: "Range", show: true, suffix: " miles" },
      { col: "Seating", label: "Seats", show: true },
      {
        calc: () => "",
        ignoreEmpty: true,
        label: "Charging access in your area",
        show: true,
        format: { name: "chargingThermometer" },
      },
    ],
  },
  {
    name: "VehicleDetailSummary",
    label: "",
    metrics: [
      {
        calc: function (vehicle, location) {
          const costPerMileData = MetricCalculations.getElectricityCostData(
            vehicle,
            location
          );
          return `<div class="row"><div class="col-4">${common.toCurrency(
            costPerMileData.electricityCostPerMile,
            3
          )}<span class="accent">/mi</span></div>
                    <div class="col-8">${Math.round(
                      costPerMileData.percentageSavingsOverGasolinePerMile,
                      0
                    )}% <span class="accent">savings over the cost of fuel</span></div></div>`;
        },
        label: "Cost to drive (estimated)",
        show: true,
        format: {
          name: "html",
        },
      },
      {
        col: "Price",
        label: "Starting at",
        show: true,
        format: {
          name: "currency",
          decimals: 0,
        },
      },
      { col: "Seating", label: "Seating for", show: true },
      { col: "Range", label: "Range", show: true, suffix: " miles" },
      {
        col: "Rebate",
        label: "Federal tax credit",
        format: {
          name: "currency",
          decimals: 0,
        },
        show: true,
        defaultValue: "Not eligible",
      },
    ],
  },
  {
    name: "Safety",
    label: "Safety",
    metrics: [
      {
        col: "Forward_Collision_Warning",
        label: "Forward collision warning",
        show: true,
        format: { name: "yesNoOptional" },
        description:
          "The driver is warned of an impending collision. Forward-looking sensors alert the driver to help them avoid or break during a collision.",
      },
      {
        col: "Lane_Departure_Warning",
        label: "Lane departure warning",
        show: true,
        format: { name: "yesNoOptional" },
        description:
          "A camera system tracks the vehicle's position in relation to the lane markings painted on the street. The driver is warned of unintentional lane shifting.",
      },
      {
        col: "Crash_Imminent_Braking",
        label: "Crash imminent braking",
        show: true,
        format: { name: "yesNoOptional" },
        description:
          "Imminent crash braking automatically applies the brakes in the event the driver doesn't take any action to avoid an impending crash. Brakes are applied to slow or stop the vehicle to reduce the severity of a crash or avoid a crash entirely.",
      },
      {
        col: "Dynamic_Brake_Support",
        label: "Dynamic brake support",
        show: true,
        format: { name: "yesNoOptional" },
        description:
          "When a driver does not apply the brakes firmly enough to avoid a crash, dynamic brake support automatically attempts to avoid a collision by supplementing the driver's own braking effort.",
      },
      {
        col: "NHTSA_Overall_Rating",
        label: "Overall safety rating",
        show: true,
        format: { name: "stars" },
        defaultValue: "Not tested",
        description:
          "The National Highway Transportation Safety Association (NHTSA) is a government organization that crash tests and evaluates vehicles safety in the United States. Overall Safety Rating is a culmination of a frontal crash, side crash, and rollover crash test results in a five-star scale (five being the best and one being the worst.",
      },
      {
        col: "NHTSA_Front_Driver_Crash_Rating",
        label: "Front driver crash rating",
        show: true,
        defaultToHidden: true,
        format: { name: "stars" },
        defaultValue: "Not tested",
        description:
          "The vehicle has the ability to read and recognize traffic signs and stoplights.",
      },
      {
        col: "NHTSA_Front_Passenger_Crash_Rating",
        label: "Front passenger crash rating",
        show: true,
        defaultToHidden: true,
        format: { name: "stars" },
        defaultValue: "Not tested",
        description:
          "Passenger safety rating in a single frontal crash. The rating represents a head-on collision between two similar vehicles, each moving at 35 mph.",
      },
      {
        col: "NHTSA_Side_Crash_Front_Rating",
        label: "Side crash front seat rating",
        show: true,
        defaultToHidden: true,
        format: { name: "stars" },
        defaultValue: "Not tested",
        description:
          "Passenger safety rating in a single frontal crash. The rating represents a head-on collision between two similar vehicles, each moving at 35 mph.",
      },
      {
        col: "NHTSA_Side_Crash_Rear_Rating",
        label: "Side crash rear seat rating",
        show: true,
        defaultToHidden: true,
        format: { name: "stars" },
        defaultValue: "Not tested",
        description:
          "Rear seat safety rating in a side impact between a single collision between a standing vehicle and one moving at 38.5 mph",
      },
      {
        col: "NHTSA_Rollover_Rating",
        label: "Rollover rating",
        show: true,
        defaultToHidden: true,
        format: { name: "stars" },
        defaultValue: "Not tested",
        description:
          "The vehicles risk of rollover in a single vehicle loss of control situation.",
      },
    ],
    tooltip: `
        <h3>Safety</h3>
        <h4>Overall Safety Rating</h4>
        <p>The National Highway Transportation Safety Association (NHTSA) is a government organization that crash tests and evaluates vehicle safety in the United States. Overall Safety Rating is a culmination of a frontal crash, side crash, and rollover crash test results on a five-star scale (five being the best and one being the worst).</p>
        <h4>Overall Front Star Rating</h4>
        <p>Driver and passenger star ratings are combined into a single frontal rating by The National Highway Transportation Safety Association (HTSA) using a frontal barrier test. This test simulates a 35 mph head-on collision between two similar vehicles. This uses a five-star scale where five stars are the best and one is the worst.</p>
        <h4>Front Seat Side Crash Rating</h4>
        <p>Driver and side-pole star ratings are combined into a front seat rating by The National Highway Transportation Safety Association (HTSA.) This uses a five-star scale where five stars are the best and one is the worst.</p>
        <h4>Rear Seat Side Crash Rating</h4>
        <p>Side Barrier Rear passenger rating is provided by The National Highway Transportation Safety Association (NHTSA.) This uses a five-star scale where five stars are the best and one is the worst.</p>
        <h4>Rollover Star Rating</h4>
        <p>This test measures the risk of a rollover in a loss-of-control scenario featuring a single-vehicle crash. This uses a five-star scale where five stars are the best and one is the worst.</p>
        <h4>Forward Collision Warning</h4>
        <p>The driver is warned of an impending collision. Forward-looking sensors alert drivers to help them avoid or break during a collision.</p>
        <h4>Lane Departure Warning</h4>
        <p>A camera system tracks the vehicle's position in relation to the lane markings painted on the street. The driver is warned of unintentional lane shifting.</p>
        <h4>Crash Imminent Braking</h4>
        <p>Imminent crash braking automatically applies the brakes if the driver doesn't take any action to avoid an impending crash. Brakes are applied to slow or stop the vehicle to reduce the crash's severity or avoid a crash entirely.</p>
        <h4>Dynamic Brake Support</h4>
        <p>When a driver does not apply the brakes firmly enough to avoid a crash, dynamic brake support automatically attempts to avoid a collision by supplementing the driver's own braking effort.</p>`,
    description:
      "To find the safest electric vehicles, we combine each vehicle’s NHTSA safety ratings and crash test data with its standard safety features to assign a safety score for each vehicle. We then divide our safety score by vehicle price to determine how much safety value each electric vehicle model provides.",
  },
  {
    name: "Cost",
    label: "Cost to Drive (Est.)",
    reverseScore: true,
    metrics: [
      {
        calc(vehicle, location) {
          const costPerMileData = MetricCalculations.getElectricityCostData(
            vehicle,
            location
          );
          return costPerMileData.electricityCostPerMile;
        },
        label: "Cost per mile (electricity)",
        show: true,
        format: {
          name: "currency",
          decimals: 3,
        },
        suffix: " / mile",
        description: {
          calc(vehicle, location) {
            return `Electricity cost to drive one mile using ${
              location?.address?.state_full || "local"
            } state utility rates at ${common.toCurrency(
              location.local_electricity_rate,
              3
            )} / Kilowatt`;
          },
        },
      },
      {
        calc(vehicle) {
          const maintCost = MetricCalculations.getMaintenanceCostData(vehicle);
          return maintCost.evMaintenancePerMile;
        },
        label: "Cost per mile (maintenance)",
        show: true,
        format: {
          name: "currency",
          decimals: 3,
        },
        suffix: " / mile",
        description: "Estimated maintenance cost to drive one mile",
      },
      {
        calc(vehicle, location) {
          const insuranceCost = MetricCalculations.getInsuranceCostData(
            vehicle,
            location
          );
          return insuranceCost.evInsuranceCostPerMile;
        },
        label: "Cost per mile (insurance)",
        show: true,
        format: {
          name: "currency",
          decimals: 3,
        },
        suffix: " / mile",
        description: "Estimated insurance cost to drive one mile",
      },
    ],
    description:
      "To calculate cost to drive, we analzye electric vehicles maintenance and insurance costs, along with cost to charge. We compare all electric vehicle models against each other to provide you with the most cost efficient EVs to own.",
    tooltip: `<h3>Cost to Drive (Estimated)</h3>
      <h4>Cost Per Mile: Electricity</h4>
      <p>Cost per mile is the price you pay for electricity per mile of driving. Cost per mile is based on using home charging paying residential utility rates.</p>
      <p>This cost varies by electric vehicle. The cost per mile calculation is based on the vehicle range (miles) of a specific model, divided by battery size (Kilowatts), multiplied by your local utility rates. Many electric utilities provide time-of-use rate plans, which offer discounts to late-night charging. These plans reduce your cost per mile.</p>
      <h4>Cost Per Mile: Maintenance</h4>
      <p>Estimate for how much you will pay for routine maintenance of your electric vehicle. This is based on our estimated electric vehicle reliability and the average maintenance cost of electric vehicles.</p>
      <h4>Cost Per Mile: Insurance</h4>
      <p>Estimated state automotive insurance based on an insurance average. We took the performance, safety, and price of the vehicle to adjust the average insurance rate to be vehicle specific. Contact an insurance provider for an accurate insurance quote.</p>
      `,
  },
  {
    name: "VehicleDetailElectricityCost",
    label: "Electricity Cost Breakdown",
    reverseScore: true,
    metrics: [
      {
        calc: function (vehicle) {
          const costPerMileData = MetricCalculations.getElectricityCostData(
            vehicle,
            location
          );
          return costPerMileData.annualElectricityCost;
        },
        label: "Annual cost",
        show: true,
        format: {
          name: "currency",
          decimals: 0,
        },
        description: `Based on national average ${common.toNumber(
          Constants.nationalMilesDrivenAverage
        )} miles driven annually`,
      },
      {
        calc(vehicle, location) {
          const costPerMileData = MetricCalculations.getElectricityCostData(
            vehicle,
            location
          );
          return costPerMileData.electricityCostPerMile;
        },
        label: "Cost per mile",
        show: true,
        format: {
          name: "currency",
          decimals: 3,
        },
        suffix: " / mile",
        description: {
          calc(vehicle, location) {
            return `Estimated electricity cost to drive one mile using ${
              location?.address?.state_full || "local"
            } state utility rates at ${common.toCurrency(
              location.local_electricity_rate,
              3
            )} / Kilowatt`;
          },
        },
      },
      {
        calc: function (vehicle) {
          const costPerMileData = MetricCalculations.getElectricityCostData(
            vehicle,
            location
          );
          return costPerMileData.annualSavingsOverGasoline;
        },
        label: "Annual savings versus gasoline",
        show: true,
        format: {
          name: "currency",
          decimals: 0,
        },
        description: {
          calc(vehicle, location) {
            return `Based on national average ${common.toNumber(
              Constants.nationalMilesDrivenAverage
            )} miles driven annually, Annual ${
              location?.address?.state_full || "local"
            } state electricity rates, and ${
              location?.local_gas_price ? "state" : "national"
            } average gasoline cost of ${common.toCurrency(
              location?.local_gas_price || Constants.nationalFuelCostAverage,
              3
            )} / gal`;
          },
        },
      },
      {
        calc: function (vehicle) {
          const costPerMileData = MetricCalculations.getElectricityCostData(
            vehicle,
            location
          );
          return costPerMileData.totalSavingsOverGasolinePerMile;
        },
        label: "Cost savings per mile versus gasoline",
        show: true,
        format: {
          name: "currency",
          decimals: 3,
        },
        description: {
          calc(vehicle, location) {
            return `Gasoline cost per mile based on average car MPG of ${
              Constants.nationalGasCarMpg
            } and ${
              location?.local_gas_price ? "state" : "national"
            } average gasoline cost of ${common.toCurrency(
              location?.local_gas_price || Constants.nationalFuelCostAverage,
              3
            )} / gal`;
          },
        },
      },
    ],
  },
  {
    name: "VehicleDetailMaintenanceCost",
    label: "Maintenance Cost Breakdown",
    reverseScore: true,
    metrics: [
      {
        calc: function (vehicle) {
          const maintCost = MetricCalculations.getMaintenanceCostData(vehicle);
          return `${common.toCurrency(maintCost.evAnnualMaintenanceCost)}`;
        },
        label: "Annual cost",
        show: true,
        description: `Estimated based on national average ${common.toNumber(
          Constants.nationalMilesDrivenAverage
        )} miles driven annually`,
      },
      {
        calc: function (vehicle) {
          const maintCost = MetricCalculations.getMaintenanceCostData(vehicle);
          return `${common.toCurrency(
            maintCost.gasAnnualMaintenanceCost -
              maintCost.evAnnualMaintenanceCost
          )}`;
        },
        label: "Annual savings versus gasoline car",
        show: true,
        description: `Estimate based on national average of ${common.toNumber(
          Constants.nationalMilesDrivenAverage
        )} miles driven annually comparing the average cost per mile of maintenance to the average gasoline vehicle`,
      },
      {
        calc: function (vehicle) {
          const maintCost = MetricCalculations.getMaintenanceCostData(vehicle);
          return `${common.toCurrency(
            maintCost.gasMaintenancePerMile - maintCost.evMaintenancePerMile,
            3
          )} / mile`;
        },
        label: "Cost savings per mile versus gasoline car",
        show: true,
        description: {
          calc(vehicle) {
            const maintCost =
              MetricCalculations.getMaintenanceCostData(vehicle);
            return `Estimate based on national average of ${common.toNumber(
              Constants.nationalMilesDrivenAverage
            )} miles driven annually comparing the average cost per mile of maintenance of ${common.toCurrency(
              maintCost.evMaintenancePerMile,
              3
            )} to the average gasoline vehicle at ${common.toCurrency(
              maintCost.gasMaintenancePerMile,
              3
            )}`;
          },
        },
      },
    ],
  },
  {
    name: "VehicleDetailTireCost",
    label: "Tire Cost Breakdown",
    reverseScore: true,
    metrics: [
      {
        calc: function (vehicle) {
          return `${common.toCurrency(
            vehicle.Cost_to_Replace_Tires,
            0
          )} every ${Math.round(
            vehicle.Tread_Life / Constants.nationalMilesDrivenAverage,
            0
          )} years or ${common.toNumber(vehicle.Tread_Life)} miles`;
        },
        label: "Replacement cost",
        show: true,
        description: `Based on OEM tire cost and national average ${common.toNumber(
          Constants.nationalMilesDrivenAverage
        )} miles driven annually`,
      },
      {
        col: "Tire_Tread_Cost_Per_Mile",
        label: "Cost per mile",
        show: true,
        format: {
          name: "currency",
          decimals: 3,
        },
        suffix: " / mile",
        description:
          "What it costs to drive a mile based on tire tread life and replacement cost.",
      },
    ],
  },
  {
    name: "VehicleDetailInsuranceCost",
    label: "Insurance Cost Breakdown",
    reverseScore: true,
    metrics: [
      {
        calc: function (vehicle, location) {
          const insuranceCost = MetricCalculations.getInsuranceCostData(
            vehicle,
            location
          );
          return `${common.toCurrency(insuranceCost.evAnnualInsuranceCost)}`;
        },
        label: "Annual cost",
        show: true,
        description: `Estimated based on national average ${common.toNumber(
          Constants.nationalMilesDrivenAverage
        )} miles driven annually`,
      },
      {
        calc: function (vehicle, location) {
          const insuranceCost = MetricCalculations.getInsuranceCostData(
            vehicle,
            location
          );
          return `${common.toCurrency(
            insuranceCost.gasAnnualInsuranceCost -
              insuranceCost.evAnnualInsuranceCost
          )}`;
        },
        label: "Annual savings versus gasoline car",
        show: true,
        description: `Estimate based on national average of ${common.toNumber(
          Constants.nationalMilesDrivenAverage
        )} miles driven annually comparing the average cost per mile of Insurance to the average gasoline vehicle`,
      },
      {
        calc: function (vehicle, location) {
          const insuranceCost = MetricCalculations.getInsuranceCostData(
            vehicle,
            location
          );
          return `${common.toCurrency(
            insuranceCost.gasInsuranceCostPerMile -
              insuranceCost.evInsuranceCostPerMile,
            3
          )} / mile`;
        },
        label: "Cost savings per mile versus gasoline car",
        show: true,
        description: {
          calc(vehicle, location) {
            const insuranceCost = MetricCalculations.getInsuranceCostData(
              vehicle,
              location
            );
            return `Estimate based on national average of ${common.toNumber(
              Constants.nationalMilesDrivenAverage
            )} miles driven annually comparing the average cost per mile of Insurance of ${common.toCurrency(
              insuranceCost.evInsuranceCostPerMile,
              3
            )} to the average gasoline vehicle at ${common.toCurrency(
              insuranceCost.gasInsuranceCostPerMile,
              3
            )}`;
          },
        },
      },
    ],
  },
  {
    name: "Performance",
    label: "Performance",
    metrics: [
      {
        col: "Horsepower",
        label: "Horsepower",
        show: true,
        description:
          "The output of power your electric motors generate using the horsepower unit of measurement used in traditional vehicles.",
        format: {
          name: "number",
        },
      },
      {
        col: "Acceleration",
        label: "Acceleration 0-60",
        show: true,
        suffix: " secs",
        description:
          "The time it takes for the electric vehicle to go from standstill to 60 miles per hour.",
      },
      {
        col: "Top_Speed",
        label: "Top speed",
        show: true,
        suffix: " mph",
        description:
          "The maximum speed in miles per hour the electric vehicle can reach.",
      },
      {
        col: "Drive_Type",
        label: "Drive type",
        show: true,
        description:
          "The type of drivetrain / which wheels receive power from the electric motors.",
      },
    ],
    description:
      "We assess top speed, acceleration, drive type, and horsepower to find the electric vehicles with the best performance.  Each performance dimension is weighted against all electric vehicle models to determine the best performing electric vehicles.",
    tooltip: `
        <h3>Performance</h3>
        <h4>Horsepower</h4>
        <p>Horsepower is a unit of measurement used to quantify the amount of power an electric motor generates.</p>
        <h4>Acceleration 0-60</h4>
        <p>The time the electric vehicle takes from a standstill to 60 miles per hour is measured in seconds.</p>
        <h4>Top Speed</h4>
        <p>The maximum speed of miles per hour the electric vehicle can reach.</p>
        <h4>Drive Type</h4>
        <p>The type of drivetrain/which wheels receive power from the electric motors.</p>
        `,
  },
  {
    name: "Range",
    label: "Range",
    description: "We take each vehicle manufacturer’s listed battery range (in miles) and compare that against all other electric vehicles to assign a range score for each vehicle. How much range for your money is also calculated by taking each electric vehicle’s range score divided by price and compare that against all other EVs.",
    metrics: [
      {
        col: "Range",
        show: true,
        label: "Range",
        description: "",
        suffix: " miles"
      },
    ],
    tooltip: `
      <h3>Range</h3>
      <p>Range is how many miles an electric vehicle can travel on a fully charged battery. 
        The distance traveled is based on ideal driving conditions that take average weather, driving speeds, and road conditions (not driving on inclines, for example. The range is sourced from electric vehicle manufacturers, and any alternations to driving conditions can reduce miles traveled on a single charge.
      </p>
    `
  },
  {
    name: "Reliability",
    label: "Reliability",
    metrics: [
      {
        calc: function (vehicle) {
          return vehicle.Basic_Warranty_Miles
            ? `${vehicle.Basic_Warranty_Years} years or 
            ${common.toNumber(vehicle.Basic_Warranty_Miles)} miles`
            : undefined;
        },
        label: "Basic warranty",
        show: true,
        description:
          "Vehicle Manufacturers provide a basic warranty to cover necessary repairs. The warranty covers a set amount of time or miles driven, whichever comes first.  Consult the car manufacturer’s website or your dealer for details on warranty details.",
      },
      {
        calc: function (vehicle) {
          return vehicle.Battery_Warranty_Miles
            ? `${vehicle.Battery_Warranty_Years} years or 
            ${common.toNumber(vehicle.Battery_Warranty_Miles)} miles`
            : undefined;
        },
        label: "Battery warranty",
        show: true,
        description:
          "Vehicle Manufacturers provide a basic warranty to cover necessary repairs. The warranty covers a set amount of time or miles driven, whichever comes first.  Consult the car manufacturer’s website or your dealer for details on warranty details.",
      },
      {
        col: "NHTSA_Complaints",
        label: "NHTSA complaints",
        show: true,
        description:
          "Consumer complaints submitted to the government agency about vehicle issues.",
      },
      {
        col: "NHTSA_Recalls",
        label: "NHTSA recalls",
        show: true,
        description:
          "A recall occurs when the government or the manufacturer deems an unacceptable safety risk and a required fix is issued",
      },
    ],
    description:
      "A reliability score is calculated by taking the number of NHTSA reported complaints and recalls compared with all other electric vehicle models. We also analyze NHTSA-reported crashes, injuries, fires, and deaths for all electric vehicles to factor in severity. We compare all EV models against each other to determine the most reliable.",
    tooltip: `<h3>Reliability</h3>
        <h4>Basic Warranty</h4>
        <p>Vehicle Manufacturers provide a basic warranty to cover necessary repairs. The warranty covers a set amount of time or miles driven, whichever comes first. Consult the car manufacturer's website or your dealer for warranty details.</p>
        <h4>Battery Warranty</h4>
        <p>Federal regulations state that an electric vehicle battery must be covered for at least eight years or 100,000 miles. Please consult the manufacturer's website for details on specific battery warranties by model.</p>
        <h4>NHTSA Complaints</h4>
        <p>The number of complaints shared with the U.S. Department of Transportation agency that handles vehicle safety. Electric Driver shares this information to give a sense of vehicle reliability.</p>
        <h4>NHTSA Recalls</h4>
        <p>The number of times a vehicle recall has been conducted to fix a defect on a specific vehicle model. Electric Driver sees recalls as a data point in tracking vehicle reliability.</p>
        `,
  },
  {
    name: "Infotainment",
    label: "Infotainment",
    metrics: [
      {
        calc: (vehicle) => {
          return `<i class="fas fa-tablet-alt" style="font-size: 1.5rem; float: left"></i><div style="padding-top: 10px"> &nbsp; ${vehicle.Display_Size}"</div>`;
        },
        label: "Display size",
        show: true,
        hideCompare: true,
        description:
          "The entertainment and vehicle information nerve center is the center console display. The size of the display has an impact on your experience.",
        format: {
          name: "html",
        },
      },
      {
        col: "Display_Size",
        label: "Display size",
        suffix: '"',
        hideDetail: true,
        show: true,
        description:
          "The entertainment and vehicle information nerve center is the center console display. The size of the display has an impact on your experience.",
      },
      {
        calc: () => "",
        ignoreEmpty: true,
        label: "Compatibility",
        show: true,
        hideCompare: true,
        description:
          "Which phones will interface directly with your vehicle's infotainment system.",
        format: {
          name: "mobileCompatibility",
        },
      },
      {
        col: "Apple_CarPlay",
        label: "Apple CarPlay",
        show: true,
        defaultToHidden: true,
        format: { name: "yesNoOptional" },
        description:
          "Denotes Apple’s standard that allows your iPhone to display and function over the vehicle's infotainment system.",
      },
      {
        col: "Android_Autos",
        label: "Android Autos",
        show: true,
        defaultToHidden: true,
        format: { name: "yesNoOptional" },
        description:
          "Androids standard that allows your Android phone to display and function over your vehicle’s infotainment system.",
      },
      {
        col: "Proprietary_Phone_Pairing",
        label: "Proprietary phone pairing",
        show: true,
        defaultToHidden: true,
        format: { name: "yesNoOptional" },
        description:
          "A standard unique to a specific vehicle manufacturer that allows any phone to connect with the infotainment system.",
      },
      {
        col: "Films",
        label: "Films",
        show: true,
        defaultToHidden: true,
        format: { name: "yesNoOptional" },
        description:
          "The ability for the vehicle infotainment to watch movies or live television through third party streaming.",
      },
      {
        col: "Games",
        label: "Games",
        show: true,
        defaultToHidden: true,
        format: { name: "yesNoOptional" },
        description:
          "Support for playing video game through the vehicle infotainment system.",
      },
      {
        col: "Streaming_Music",
        label: "Streaming music",
        show: true,
        defaultToHidden: true,
        format: { name: "yesNoOptional" },
        description:
          "The ability to support streaming music either through your mobile phone or through a music streaming service.",
      },
      {
        col: "Online_Search",
        label: "Online search",
        show: true,
        defaultToHidden: true,
        format: { name: "yesNoOptional" },
        description:
          "The vehicle is connected to the internet and provides connectivity. Online vehicle connectivity adds an additional annual cost.",
      },
      {
        col: "Voice_Control",
        label: "Voice control",
        show: true,
        defaultToHidden: true,
        format: { name: "yesNoOptional" },
        description: "Vehicle functions can be controlled via verbal phrases.",
      },
      {
        col: "Charging_Planner",
        label: "Charging planner",
        show: true,
        defaultToHidden: true,
        format: { name: "yesNoOptional" },
        description:
          "Built into the vehicle navigation is the ability to chart when and where you need to charge your vehicle in order to get to your destination.",
      },
      {
        col: "Passenger_Display",
        label: "Passenger display",
        show: true,
        defaultToHidden: true,
        format: { name: "yesNoOptional" },
        description:
          "Addition infotainment display screens for passengers. Possible locations include built into headrests or above the glovebox.",
      },
      {
        col: "Navigation",
        label: "Navigation",
        show: true,
        defaultToHidden: true,
        format: { name: "yesNoOptional" },
        description:
          "Infotainment has navigation and route planning capabilities.",
      },
      {
        col: "OTA_Updates",
        label: "OTA updates",
        show: true,
        defaultToHidden: true,
        format: { name: "yesNoOptional" },
        description:
          "Over the air updates (OTA) allow your vehicle like your mobile phone to receive software updates, fixes and improvements.",
      },
    ],
    description:
      "Infotainment is scored by comparing features like large displays, entertainment systems, native phone pairing, over the air software software updates, and ability to stream media and play games. All electric vehicle models are scored against each other to determine which infotainment system is the best.",
    tooltip: `<h3>Infotainment</h3>
        <h4>Infotainment</h4>
        <p>Infotainment is like the nerve center of your vehicle handling, navigation, vehicle functions, audio system, and entertainment features. The infotainment system with electric vehicles includes the trip planner that combines navigation with electric charge management. If you take a trip and require charging along the way, the trip planner lets you know where to stop for charging, how long you should charge, and manages your trips.</p>
        `,
  },
  {
    name: "Cargo",
    label: "Cargo",
    metrics: [
      {
        col: "Storage_Capacity",
        label: "Storage capacity",
        show: true,
        suffix: " cu. ft.",
        description:
          "The total storage capacity in cubic square feet the vehicle can carry. Typically storage combines front truck storage plus rear truck with folded seat capacity.",
      },
      {
        col: "Storage_Seats_Folded",
        label: "Storage with seats Folded",
        show: true,
        suffix: " cu. ft.",
        description:
          "The maximum storage capacity with rear seats folded flat.",
      },
    ],
    description:
      "Cargo capacity determines which electric vehicle has the most storage space. We look at cargo capacity for seat upright plus total cargo capacity and compare against other electric vehicles to determine which EV has the largest cargo capacity.",
    tooltip: `<h3>Cargo</h3>
        <h4>Cargo Capacity</h4>
        <p>The total storage capacity in cubic square feet the vehicle can carry. Typically, storage combines front truck storage plus rear truck with folded seat capacity.</p>
        <h4>Storage with Seats Folded</h4>
        <p>The total rear storage capacity with seats folded in cubic square feet.</p>
        `,
  },
  {
    name: "Charging",
    label: "Charging",
    metrics: [
      {
        col: "Level3_Charge_Time",
        label: "Level 3 (DC Fast) 80% recharge time",
        show: true,
        suffix: " minutes",
        description:
          "Time it takes to charge vehicle battery from 10% to 80% using a DC fast charger (fastest available option).",
      },
      {
        col: "Level2_Charge_Time",
        label: "Level 2 full recharge time",
        show: true,
        suffix: " hours",
        description:
          "Time it takes to charge vehicle battery fully using a 240 volt charger (Home or destination charger).",
      },
      {
        col: "Public_Level3_Locations",
        label: "Charging stations nationally",
        show: true,
        format: { name: "number", decimals: 0 },
        description:
          "Total level 3 (DC fast) charging stations available in the USA that are compatible with your selected electric vehicle.",
      },
      {
        calc: function (vehicle, location) {
          
          return `<a href="/ChargerFinder?ymms=${vehicle.YMMS_Desc}&zip=${location.address.zip}">view map &raquo;</a>`;
        },
        label: "Local charging map",
        show: true,
        format: {
          name: "html",
        },
      },
    ],
    description:
      "We selected vehicles with the fastest charge times at home or away, so you can get back on the road faster when you’re out of juice. We also factored in models with widely available chargers nationwide for the most convenient charging while traveling.",
    tooltip: `<h3>Charging</h3>
        <h4>Level 3 80% Recharge Time</h4>
        <p>DC Fast Charging (Level 3 Charging or Supercharging) is the fastest available commercial charging from third-party charging stations. Charging speeds depend on battery capacity, charging output, and weather conditions. Typical charging times from 10% to 80% cha typically take 30 to 60 minutes, depending on the vehicle model.</p>       
        <h4>Level 2 Full Recharge Time</h4>
        <p>A level 2 charger is an electric vehicle charger that uses 240 volts and is primarily used for overnight charging. Level 2 chargers will provide 15-40 miles of drive time per hour spent recharging. Time to charge your vehicle fully may take 5 to 13 hours, depending on the vehicle model.</p>
        `,
  },
];
