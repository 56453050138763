import { Constants } from "./Constants"

export const MetricCalculations = {
  getElectricityCostData(vehicle, location) {
    const rate = location?.local_electricity_rate || Constants.nationalElectricityRate
    const electricityCostPerMile = rate / (vehicle.Range / vehicle.Battery_Size)
    const gasCostPerMile = (location?.local_gas_price || Constants.nationalFuelCostAverage) / Constants.nationalGasCarMpg
    const annualElectricityCost = electricityCostPerMile * Constants.nationalMilesDrivenAverage
    const totalSavingsOverGasolinePerMile = gasCostPerMile - electricityCostPerMile
    const percentageSavingsOverGasolinePerMile = (totalSavingsOverGasolinePerMile / gasCostPerMile) * 100
    const annualSavingsOverGasoline = totalSavingsOverGasolinePerMile * Constants.nationalMilesDrivenAverage
    const annualGasolineCost = gasCostPerMile * Constants.nationalMilesDrivenAverage

    return {
      electricityCostPerMile,
      annualElectricityCost,
      totalSavingsOverGasolinePerMile,
      percentageSavingsOverGasolinePerMile,
      annualSavingsOverGasoline,
      annualGasolineCost
    }
  },
  getTireCostData(vehicle) {
    const annualTireCost = vehicle.Cost_to_Replace_Tires / (vehicle.Tread_Life / Constants.nationalMilesDrivenAverage)
    return {
      annualTireCost
    }
  },
  getMaintenanceCostData(vehicle) {
    const evMaintenancePerMile = vehicle.Maintenance_Cost_Per_Mile || 0;
    const evAnnualMaintenanceCost = evMaintenancePerMile * Constants.nationalMilesDrivenAverage;
    const gasMaintenancePerMile = Constants.gasCarMaintenancePerMile;
    const gasAnnualMaintenanceCost = gasMaintenancePerMile * Constants.nationalMilesDrivenAverage;
    const annualSavingsOverGasoline = gasAnnualMaintenanceCost - evAnnualMaintenanceCost;
    const totalSavingsOverGasolinePerMile = gasMaintenancePerMile - evMaintenancePerMile;

    return {
      evMaintenancePerMile,
      evAnnualMaintenanceCost,
      gasMaintenancePerMile,
      gasAnnualMaintenanceCost,
      annualSavingsOverGasoline,
      totalSavingsOverGasolinePerMile
    }
  },
  getInsuranceCostData(vehicle, location){
    const insuranceRate = location?.local_insurance_rate || 0;
    const evAnnualInsuranceCost = insuranceRate + (insuranceRate * vehicle.Insurance_Modifier)
    const gasAnnualInsuranceCost = insuranceRate
    const evInsuranceCostPerMile = evAnnualInsuranceCost / Constants.nationalMilesDrivenAverage;
    const gasInsuranceCostPerMile = gasAnnualInsuranceCost / Constants.nationalMilesDrivenAverage;
    const annualSavingsOverGasoline = gasAnnualInsuranceCost - evAnnualInsuranceCost;
    const totalSavingsOverGasolinePerMile = gasInsuranceCostPerMile - evInsuranceCostPerMile ;
    return {
      insuranceRate,
      evAnnualInsuranceCost,
      gasAnnualInsuranceCost,
      evInsuranceCostPerMile,
      gasInsuranceCostPerMile,
      annualSavingsOverGasoline,
      totalSavingsOverGasolinePerMile
    }
  }
}