<template>
  <div id="app">
    <b-navbar toggleable="lg" type="dark" class="py-1">
      <b-navbar-toggle target="Collapse"></b-navbar-toggle>
      <b-navbar-brand to="/">
        <img id="nav-logo" src="~Images/logo_small.png" />
      </b-navbar-brand>
      <b-collapse id="Collapse" is-nav>
        <b-navbar-nav id="navbarNav">
          <b-nav-item to="/"> VEHICLES </b-nav-item>
          <b-nav-item to="/incentives"> INCENTIVES </b-nav-item>
          <b-nav-item href="https://electricdriver.co/articles/">
            ARTICLES
          </b-nav-item>
          <b-nav-item to="/ChargerFinder">
            CHARGING MAP
          </b-nav-item>
          <b-nav-item-dropdown text="GLOSSARY">
            <b-dropdown-item href="https://electricdriver.co/articles/electric-vehicle-autopilot/"
              >Autopilot</b-dropdown-item
            >
            <b-dropdown-item
              href="https://electricdriver.co/articles/electric-vehicle-carbon-emissions/"
              >Carbon Emissions</b-dropdown-item
            >
            <b-dropdown-item
              href="https://electricdriver.co/articles/electric-vehicle-cargo-capacity/"
              >Cargo Capacity</b-dropdown-item
            >
            <b-dropdown-item
              href="https://electricdriver.co/articles/electric-vehicle-charging-time/"
              >Charging Time</b-dropdown-item
            >
            <b-dropdown-item
              href="https://electricdriver.co/articles/electric-vehicle-cost-to-drive/"
              >Cost to Drive</b-dropdown-item
            >
            <b-dropdown-item
              href="https://electricdriver.co/articles/electric-vehicle-infotainment/"
              >Infotainment</b-dropdown-item
            >
            <b-dropdown-item href="https://electricdriver.co/articles/electric-vehicle-performance/"
              >Performance</b-dropdown-item
            >
            <b-dropdown-item href="https://electricdriver.co/articles/electric-vehicle-reliability/"
              >Reliability</b-dropdown-item
            >
            <b-dropdown-item href="https://electricdriver.co/articles/electric-vehicle-safety/"
              >Safety</b-dropdown-item
            >
          </b-nav-item-dropdown>
          <b-nav-item href="#" to="/About" class="d-md-none">
            ABOUT US
          </b-nav-item>
          <b-nav-item to="/Terms" class="d-md-none">
            TERMS &amp; CONDITIONS
          </b-nav-item>
          <b-nav-item to="/PrivacyPolicy" class="d-md-none">
            PRIVACY POLICY
          </b-nav-item>
          <b-nav-item to="/CookiePolicy" class="d-md-none">
            COOKIE POLICY
          </b-nav-item>
          <b-nav-item to="/AffiliateDisclaimer" class="d-md-none">
            AFFILIATE DISCLAIMER
          </b-nav-item>
        </b-navbar-nav>
        <b-navbar-nav right id="SocialLinks" class="ml-auto">
            <b-nav-item
              href="https://www.instagram.com/electricdriver/"
              target="_blank"
              alt="Instagram"
            >
              <i class="fab fa-instagram"></i
              ><span class="d-md-none"> Instagram</span>
            </b-nav-item>
            <b-nav-item
              href="https://www.facebook.com/electricdrvr"
              target="_blank"
            >
              <i class="fab fa-facebook-f"></i>
              <span class="d-md-none"> Facebook</span>
            </b-nav-item>
            <b-nav-item href="https://twitter.com/ElectricDrvr" target="_blank">
              <i class="fab fa-twitter"></i
              ><span class="d-md-none"> Twitter</span>
            </b-nav-item>
            <b-nav-item
              href="https://www.linkedin.com/company/electric-driver/"
              target="_blank"
            >
              <i class="fab fa-linkedin-in"></i
              ><span class="d-md-none"> LinkedIn</span>
            </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <div id="mainContent">
      <!-- <div class="d-sm-none">xs</div>
      <div class="d-none d-sm-block d-md-none">sm</div>
      <div class="d-none d-md-block d-lg-none">md</div>
      <div class="d-none d-lg-block d-xl-none">lg</div>
      <div class="d-none d-xl-block">xl</div> -->
      <div v-show="!loading.loaded" class="row text-center loading">
        <div class="col-12">
          <h2>{{ loading.text }}</h2>
          <div class="spinner-border text-light" role="status">
            <span class="sr-only">{{ loading.text }}</span>
          </div>
        </div>
      </div>
      <router-view v-show="loading.loaded" />
    </div>
    <footer
      class="row no-gutters justify-content-center footer d-none d-md-flex py-2"
    >
      <router-link to="/">HOME</router-link> |
      <router-link to="/About">ABOUT US</router-link> |
      <router-link to="/Terms">TERMS &amp; CONDITIONS</router-link> |
      <router-link to="/PrivacyPolicy">PRIVACY POLICY</router-link> |
      <router-link to="/CookiePolicy">COOKIE POLICY</router-link> |
      <router-link to="/AffiliateDisclaimer">AFFILIATE DISCLAIMER</router-link>
      |
      <a
        href="mailto:info@electricdriver.co?subject=Electric%20Driver%20Inquiry"
        >CONTACT US</a
      >
    </footer>
  </div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue/dist/bootstrap-vue.min.css";

export default {
  name: "App",
  metaInfo: {
    title: "Electric Driver",
    titleTemplate: "%s | Electric Driver",
    meta: [
      {
        name: "description",
        content:
          "Electric car shopping, simplified. Research and compare pricing, features, maintenance costs, and environmental impact of electric vehicles from every major brand.",
      },
    ],
  },
  computed: {
    loading() {
      return this.$store.getters.$loading;
    }
  },
};
</script>

<style lang="scss">
#mainContent {
  min-height: 92vh;
  background: $background-image-color no-repeat url("~Images/primary_bg.jpg");
  background-position: 50% 0;
  background-size: auto;

  .loading {
    font-family: $primary-header-font-family;
    font-weight: bolder;
    min-height: 92vh;
    padding-top: 300px;
    background: url("~Images/primary_bg.jpg") $background-image-color repeat-x;
  }
}

.navbar {
  z-index: 1000;
  background-color: $nav-color;
  padding-bottom: 7px;
  color: $nav-font-color;

  @include media-breakpoint-up(lg) {
    height: 35px;

    #SocialLinks {
      font-size: 1.2rem;
      padding-top: 5px;
    }

    #navbarNav {
      li.nav-item {
        padding-left: 25px;
        position: relative;
        top: -10px;
      }
      li.nav-item::before {
        position: relative;
        content: "\2022";
        color: $nav-bullet-color;
        top: 28px;
        left: -15px;
      }
    }
  }

  ul.dropdown-menu {
    background-color: black;

    .dropdown-item:hover {
      background-color: black;
      opacity: 0.5;
    }
  }

  #nav-logo {
    padding-left: 10px;
    @include media-breakpoint-down(md) {
      width: 200px;
    }
  }

  a.navbar-brand:hover {
    cursor: pointer
    
  }
  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='3' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
    height: 20px;
  }

  .navbar-toggler {
    position: relative;
    left: -20px;
  }

  a {
    color: $nav-font-color !important;
    font-weight: bold;
  }

  .nav-icon {
    padding-right: 5px;
    padding-top: 4px;
  }
}

.footer {
  background: black;
  width: 100%;

  a {
    color: $primary-font-color-light;
    padding-right: 10px;
    padding-left: 10px;
    font-weight: bold;
  }
}
</style>