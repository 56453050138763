import VuexPersistence from "vuex-persist";
import LocationService from "Services/LocationService";
import ElectricDriverApi from "Services/ElectricDriverApi";
import { DEFAULT_ZIP } from "../config";
import { FeatureMap } from "Services/FeatureMap";
import { Constants } from "Services/Constants";

const DEFAULT_FEATURES = Constants.features.map((x, i) => ({
  name: x.value,
  rank: i + 1,
}));

//Setup Vuex State
const vuexLocal = new VuexPersistence({
  storage: window.sessionStorage,
  reducer: (state) => ({
    searchOptions: state.searchOptions,
    addressState: state.locationInfo?.address?.state,
  }),
});

export const mainStore = {
  state: {
    searchOptions: {
      priceLow: 40000,
      priceHigh: 70000,
      selectedPriceCategory: undefined,
      seatingCapacity: 4,
      dailyDistance: 100,
      features: [],
      zip: DEFAULT_ZIP,
      bodyStyles: []
    },
    loadingInfo: {
      loadingCounter: 0,
      text: [],
    },
    locationInfo: {
      address: {
        city: "",
        state: "",
        zip: "",
        coordinates: {
          lat: 0,
          lng: 0,
        },
      },
      local_electricity_rate: 0,
      local_charging_stations: [],
      local_gas_price: 0,
      local_emissions_data: null,
      local_incentives: [],
    },
    allYmms: [],
    allBodyStyles: [],
    recentArticles: [],
    featuredArticle: {},
    recommendedVehicles: [],
    featureMap: FeatureMap,
    chargingStationInfo: {
      location: {
        lat: 0,
        lng: 0,
      },
      stations: [],
    },
    featuredVehicles: [
    ]
  },
  mutations: {
    updateOptions(state, options) {
      if (!options.features) options.features == state.searchOptions.features;
      state.searchOptions = { ...state.searchOptions, ...options };
    },
    updateLocation(state, location) {
      state.locationInfo = location;
    },
    loaded(state) {
      state.loadingInfo.loadingCounter--;
      if (state.loadingInfo.loadingCounter > 0) {
        setTimeout(() => state.loadingInfo.text.splice(0, 1), 1000);
      } else {
        state.loadingInfo.text.splice(0, 1);
      }
    },
    loading(state, text) {
      state.loadingInfo.text.push(text);
      state.loadingInfo.loadingCounter++;
    },
    updateAllYmms(state, ymms) {
      state.allYmms = ymms;
    },
    updateAllBodyStyles(state, bodyStyles) {
      state.allBodyStyles = bodyStyles;
    },
    updateRecentArticles(state, articles) {
      state.recentArticles = articles;
    },
    updateFeaturedArticle(state, article) {
      state.featuredArticle = article;
    },
    updateRecommendedVehicles(state, vehicles) {
      state.recommendedVehicles = vehicles;
    },
    updateChargingStationInfo(state, chargingStationInfo) {
      state.chargingStationInfo = chargingStationInfo;
    },
    updateFeaturedVehicles(state, vehicles) {
      state.featuredVehicles = vehicles;
    }
  },
  actions: {
    fetchLocationInfo({ state, commit }) {
      commit("loading", "Getting local rates and charging stations...");
      return LocationService.getLocationInfo(state.searchOptions.zip)
        .then((result) => {
          commit("updateLocation", result);
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          commit("loaded");
        });
    },
    fetchAllYMMS({ commit }) {
      return ElectricDriverApi.getAllYMMS().then((result) => {
        commit("updateAllYmms", result);
      });
    },
    async fetchAllBodyStyles({ commit }) {
      const response = await ElectricDriverApi.getAllBodyStyles();
      commit("updateAllBodyStyles", response);
    },
    fetchRecentArticles({ commit }) {
      return ElectricDriverApi.getRecentArticles().then((result) => {
        commit("updateRecentArticles", result);
      });
    },
    fetchFeaturedArticle({ commit }) {
      return ElectricDriverApi.getFeaturedArticle().then((result) => {
        commit("updateFeaturedArticle", result);
      });   
    },
    fetchRecommendedVehicles({ state, commit }) {
      commit("loading", "Finding your perfect match...");
      if (state.searchOptions.features.length === 0) {
        state.searchOptions.features = DEFAULT_FEATURES;
      }
      return ElectricDriverApi.getRecommendations(state.searchOptions)
        .then((result) => {
          commit("updateRecommendedVehicles", result);
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          commit("loaded");
        });
    },
    fetchChargingStationsByZip({ state, commit }, { ymms, zip, radius }) {
      if (!zip) {
        zip = state.searchOptions.zip || DEFAULT_ZIP;
      }
      return ElectricDriverApi.getChargingStationsByVehicleAndZip(
        ymms,
        zip,
        radius
      ).then((result) => {
        commit("updateChargingStationInfo", result);
      });
    },
    fetchChargingStationsByCoords({ commit }, { ymms, lat, lng, radius }) {
      return ElectricDriverApi.getChargingStationsByVehicleAndCoords(
        ymms,
        lat,
        lng,
        radius
      ).then((result) => {
        commit("updateChargingStationInfo", result);
      });
    },
    loadComparisonData({ commit, dispatch }, searchFilters) {
      if (searchFilters) {
        commit("updateOptions", {
          priceLow: searchFilters.price?.min,
          priceHigh: searchFilters.price?.max,
          seatingCapacity: searchFilters.seats,
          dailyDistance: searchFilters.range,
          zip: searchFilters.zip,
          bodyStyles: searchFilters.bodyStyles
        });
      }
      return Promise.all([
        dispatch("fetchRecommendedVehicles"),
        dispatch("fetchLocationInfo"),
      ]);
    },
    fetchFeaturedVehicles({ commit }) {
      return ElectricDriverApi.getFeaturedVehicles().then((result) => {
        commit("updateFeaturedVehicles", result);
      });
    }
  },
  getters: {
    $loading(state) {
      return {
        loaded: state.loadingInfo.loadingCounter === 0,
        text: state.loadingInfo.text[0],
      };
    },
    filterChargingLocations(state) {
      return (chargingLevel) => {
        if (chargingLevel == "dc_fast" || chargingLevel == "level2") {
          return state.chargingStationInfo.stations.filter(
            (x) => x[`${chargingLevel}_charger_count`] > 0
          );
        }
        return state.chargingStations;
      };
    },
    selectedFeatureDetails(state) {
      return state.searchOptions?.features.map((f) =>
        state.featureMap.find((x) => x.name == f.name)
      );
    },
    recommendationMatchCount(state) {
      return state.recommendedVehicles?.filter((x) => x.Matched === true)
        ?.length;
    },
  },
  plugins: [vuexLocal.plugin],
};
