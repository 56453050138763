export const Constants = {
  driveDistanceOptions: [100, 150, 200, 250, 300, 350, 400],
  seatOptions: [4, 5, 6, 7],
  priceOptions: [...Array(36).keys()].map((i) => (i + 5) * 5000),
  nationalGasCarMpg: 24.2,
  nationalMilesDrivenAverage: 13476,
  nationalFuelCostAverage: 3.071,
  nationalElectricityRate: 0.1319,
  gasCarMaintenancePerMile: 0.061,
  evCarMaintenancePerMile: 0.031,
  treesSavedPerTonCO2: 16.5,
  gasCarCO2Annually: 5,
  states: [
    { name: "Alabama", abbr: "AL" },
    { name: "Alaska", abbr: "AK" },
    { name: "Arizona", abbr: "AZ" },
    { name: "Arkansas", abbr: "AR" },
    { name: "California", abbr: "CA" },
    { name: "Colorado", abbr: "CO" },
    { name: "Connecticut", abbr: "CT" },
    { name: "Delaware", abbr: "DE" },
    { name: "District of Columbia", abbr: "DC" },
    { name: "Florida", abbr: "FL" },
    { name: "Georgia", abbr: "GA" },
    { name: "Hawaii", abbr: "HI" },
    { name: "Idaho", abbr: "ID" },
    { name: "Illinois", abbr: "IL" },
    { name: "Indiana", abbr: "IN" },
    { name: "Iowa", abbr: "IA" },
    { name: "Kansas", abbr: "KS" },
    { name: "Kentucky", abbr: "KY" },
    { name: "Louisiana", abbr: "LA" },
    { name: "Maine", abbr: "ME" },
    { name: "Maryland", abbr: "MD" },
    { name: "Massachusetts", abbr: "MA" },
    { name: "Michigan", abbr: "MI" },
    { name: "Minnesota", abbr: "MN" },
    { name: "Mississippi", abbr: "MS" },
    { name: "Missouri", abbr: "MO" },
    { name: "Montana", abbr: "MT" },
    { name: "Nebraska", abbr: "NE" },
    { name: "Nevada", abbr: "NV" },
    { name: "New Hampshire", abbr: "NH" },
    { name: "New Jersey", abbr: "NJ" },
    { name: "New Mexico", abbr: "NM" },
    { name: "New York", abbr: "NY" },
    { name: "North Carolina", abbr: "NC" },
    { name: "North Dakota", abbr: "ND" },
    { name: "Ohio", abbr: "OH" },
    { name: "Oklahoma", abbr: "OK" },
    { name: "Oregon", abbr: "OR" },
    { name: "Pennsylvania", abbr: "PA" },
    { name: "Puerto Rico", abbr: "PR" },
    { name: "Rhode Island", abbr: "RI" },
    { name: "South Carolina", abbr: "SC" },
    { name: "South Dakota", abbr: "SD" },
    { name: "Tennessee", abbr: "TN" },
    { name: "Texas", abbr: "TX" },
    { name: "Utah", abbr: "UT" },
    { name: "Vermont", abbr: "VT" },
    { name: "Virginia", abbr: "VA" },
    { name: "Washington", abbr: "WA" },
    { name: "West Virginia", abbr: "WV" },
    { name: "Wisconsin", abbr: "WI" },
    { name: "Wyoming", abbr: "WY" },
  ],
  features: [
    {
      name: "Safety",
      value: "Safety",
      selected: false,
    },
    {
      name: "Range",
      value: "Range",
      selected: false,
    },
    {
      name: "Reliability",
      value: "Reliability",
      selected: false,
    },
    {
      name: "Performance",
      value: "Performance",
      selected: false,
    },
    {
      name: "Infotainment",
      value: "Infotainment",
      selected: false,
    },
    {
      name: "Charging Time",
      value: "Charging",
      selected: false,
    },
    {
      name: "Cost to Drive",
      value: "Cost",
      selected: false,
    },
    {
      name: "Cargo Capacity",
      value: "Cargo",
      selected: false,
    },
  ],
  prosCons: {
    Tesla: {
      pros: [
        "Reduce fuel costs over gasoline-powered vehicle",
        "Skip the gas station: charging can be done at home overnight",
        "Over-the-air updates reduce dealer service trips",
        "EVs are eligible to drive in the carpool lane",
        "Environmentally friendly",
      ],
      cons: [
        "Teslas can only be serviced at a Tesla dealership",
        "Shorter vehicle range versus gasoline-powered vehicles",
        "Longer charge times versus gas pump",
        "In extreme weather, there is a slight-to-moderate driving range loss",
      ],
    },
    Other: {
      pros: [
        "Reduce fuel costs over gasoline-powered vehicle",
        "Skip the gas station: charging can be done at home overnight",
        "Over-the-air updates reduce dealer service trips",
        "EVs are eligible to drive I the carpool lane",
        "Environmentally friendly",
      ],
      cons: [
        "Electric Vehicles can only be serviced by dealerships",
        "Shorter vehicle range versus gasoline-powered cars",
        "Longer charge times versus gas pump",
        "In extreme weather, there is a slight to moderate range loss",
      ],
    },
  },
  chargers: [
    {
      id: 1,
      name: "Tesla Wall Connector",
      description: `The Tesla wall connector is a home charging solution to charge your Tesla anytime. 
        Through Tesla's mobile application, you can charge your electric vehicle and receive notifications. 
        The charger is also wi-fi capable, allowing the charger to receive over the air firmware updates when available.`,
      price: 500,
      url: "https://shop.tesla.com/product/wall-connector",
      warrantyYears: 4,
      installationPriceLow: 400,
      installationPriceHigh: 1200,
      imgName: "tesla_wall_charger",
      features: [
        "Up to 44 miles (77km) of range per hour of charge",
        "Up to 11.5 kW / 48 amp output",
        "Customizable power levels on a range of circuit breakers",
        "Compatible with any home electrical system",
        "Wi-Fi connectivity (2.4 GHz 802.11 b/g/n)",
        "Approved for indoor and outdoor installation",
        "Lightweight 18' (5.5m) cable length",
        "Tempered white glass faceplate",
      ],
    },
    {
      id: 2,
      name: "ChargePoint Home Flex",
      description: `The ChargePoint charger works with any electric vehicle and charges up to nine times faster than using a standard wall outlet. Schedule to charge when electricity is cheapest using the Charge Point app and is Alexa compatible.`,
      price: 699,
      url: "https://www.chargepoint.com/drivers/home/chargepoint-home-flex/",
      warrantyYears: 3,
      installationPriceLow: 400,
      installationPriceHigh: 1200,
      imgName: "chargepoint_wall_charger",
      features: [
        "Up to 9 times faster charging than a standard wall outlet",
        "Works with any electric vehicle",
        "Up to 50 amp output",
        "Compatible with any home electrical system",
        "Wi-Fi connectivity using ChargePoint App",
        "Approved for indoor and outdoor installation",
        "Over the air firmware updates",
        "Lightweight 23 feet cable length",
        "Three-year warranty",
      ],
    },
    {
      id: 3,
      name: "Enel X Juice Box 40",
      description: `The Juicebox 40 charger works with any electric vehicle and charges up to seven times faster than using a standard wall outlet. In addition, JuiceBox supports wifi and Alexa compatibility.`,
      price: 649,
      url: "https://amzn.to/37fxFij",
      warrantyYears: 3,
      installationPriceLow: 400,
      installationPriceHigh: 1200,
      imgName: "juice_box_40_charger",
      features: [
        "25 foot cable Length",
        "Weatherproof",
        "Charging speeds up to 35 miles per hour",
        "Supports all electric vehicles",
        "Wi-fi enabled",
        "Amazon Alexa and Google compatible",
        "3 year warranty",
      ],
    },
  ],
};
