import axios from "axios";
import { API_BASE_URL, SEO_BASE_URL } from "../config";

export default new (class {
  getRecommendations(searchOptions) {
    const service = axios.create();
    return service
      .post(`${API_BASE_URL}/recommend`, searchOptions, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      })
      .then((searchResults) => searchResults.data)
      .catch((err) => console.error(err));
  }

  async getVehicleById(id) {
    const url = `${API_BASE_URL}/detail/${id}`;
    return await axios
      .get(url)
      .then((response) => response.data)
      .catch((err) => console.error(err));
  }

  async getVehicleByYMMS(ymms_desc) {
    const url = `${API_BASE_URL}/detail/${ymms_desc}`;
    return await axios
      .get(url)
      .then((response) => response.data)
      .catch((err) => console.error(err));
  }

  async getAllYMMS() {
    const url = `${API_BASE_URL}/vehicles`;
    return await axios
      .get(url)
      .then((response) => response.data)
      .catch((err) => console.error(err));
  }

  async getAllBodyStyles() {
    const url = `${API_BASE_URL}/vehicles/styles`;
    return await axios
      .get(url)
      .then((response) => response.data)
      .catch((err) => console.error(err));
  }

  async getEbayListings(year, make, model, series, offset = 0) {
    const url = `${API_BASE_URL}/ebay_info?year=${year}&make=${make}&model=${model}&series=${series}&offset=${offset}`;
    return await axios
      .get(url)
      .then((response) => response.data)
      .catch((err) => {
        if (err && err.response && err.response.status === 404) {
          return [];
        } else {
          console.err(err);
          return null;
        }
      });
  }

  async getDetailSlugs() {
    const url = `${SEO_BASE_URL}/vehicles`;
    return await axios
      .get(url)
      .then((response) => {
        return response.data.map((x) => ({
          ymms_desc: `${x.Year}-${x.Make}-${x.Model}-${x.Trim.replace(
            " ",
            "-"
          )}`.replace(" ", "-"),
        }));
      })
      .catch((err) => console.error(err));
  }

  async getRecentArticles() {
    const url = `${API_BASE_URL}/articles/recent?limit=2`;
    return await axios.get(url).then((response) => response.data);
  }

  async getFeaturedArticle() {
    const url = `${API_BASE_URL}/articles/featured`;
    return await axios.get(url).then((response) => response.data);
  }

  async getChargingStationsByVehicleAndZip(ymms, zip, radius = null) {
    let url = `${API_BASE_URL}/location/chargers?`;
    if (zip) {
      url = `${url}zip=${zip}`;
    }
    if (ymms) {
      url = `${url}&ymms=${ymms}`;
    }
    if (radius) {
      url = `${url}&radius=${radius}`;
    }
    return await axios.get(url).then((response) => response.data);
  }

  async getChargingStationsByVehicleAndCoords(ymms, lat, lng, radius = null) {
    let url = `${API_BASE_URL}/location/chargers?`;
    if (lat && lng) {
      url = `${url}lat=${lat}&lng=${lng}`;
    }
    if (ymms) {
      url = `${url}&ymms=${ymms}`;
    }
    if (radius) {
      url = `${url}&radius=${radius}`;
    }
    return await axios.get(url).then((response) => response.data);
  }

  async getFeaturedVehicles() {
    const url = `${API_BASE_URL}/vehicles/featured`;
    return await axios.get(url).then((response) => response.data);
  }

  async getVehicleImageUrl(vehicle, imgType, imgSizeType) {
    const url = `${API_BASE_URL}/image?year=${vehicle.Year}&make=${vehicle.Make}&model=${vehicle.Model}&series=${vehicle.Trim}&type=${imgType}&size=${imgSizeType}`;
    try {
      const response = await axios.get(url);
      if (response.status === 200) {
        return response.data;
      }
    } catch (err) {
      return null;
    }
  }
})();
