import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import { routes } from "./routes"
import Vuex from 'vuex'
import { mainStore } from "./store/index"
import * as VueGoogleMaps from 'vue2-google-maps'
import { GOOGLE_MAPS_CONFIG } from "./config"
import Router from 'vue-router'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'
import VueSanitize from "vue-sanitize"

//VueSanitize
Vue.use(VueSanitize)

//VueMeta
Vue.use(VueMeta)

//Bootstrap
Vue.use(BootstrapVue, IconsPlugin)

//Vuex
Vue.use(Vuex)
const store = new Vuex.Store(mainStore)

//VueRouter
Vue.use(Router)
const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior(to) {
        if (to.hash) {
            return {
                selector: to.hash,
                behavior: 'smooth'

            }
        }
        return { x: 0, y: 0 }
    }
})

//Google Maps
Vue.use(VueGoogleMaps, {
    load: {
        key: GOOGLE_MAPS_CONFIG.API_KEY,
        libraries: 'places',
        map_ids: GOOGLE_MAPS_CONFIG.MAP_IDS
    }
});


Vue.config.productionTip = false

Vue.config.ignoredElements = ['row', /^col(\d|x)*$/];
new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')