const VehicleSearch = () => import("Views/VehicleSearch");
const CompareVehicles = () => import("Views/CompareVehicles");
const VehicleDetail = () => import("Views/VehicleDetail");
const AboutUs = () => import("Views/AboutUs");
const TermsOfUse = () => import("Views/TermsOfUse");
const PrivacyPolicy = () => import("Views/PrivacyPolicy");
const CookiePolicy = () => import("Views/CookiePolicy");
const AffiliateDisclaimer = () => import("Views/AffiliateDisclaimer");
const Incentives = () => import("Views/Incentives");
const ChargerFinder = () => import("Views/ChargerFinder")

import ElectricDriverApi from "./services/ElectricDriverApi";
import { Constants } from "./services/Constants";

export const routes = [
  {
    path: "/",
    name: "search",
    component: VehicleSearch,
  },
  {
    path: "/compare",
    name: "compare",
    component: CompareVehicles,
    props: (route) => ({
      ...route.params,
    })
  },
  {
    path: "/detail/:id(\\d+)",
    name: "detail",
    component: VehicleDetail,
    props: (route) => ({
      id: route.params.id,
      lat: route.query.lat,
      lng: route.query.lng,
      searchOptions: route.params.searchOptions,
      direct: route.query.direct === "true",
    }),
    meta: {
      sitemap: { ignoreRoute: true },
    },
  },
  {
    path: "/detail/:ymms_desc",
    name: "detail_ymms_desc",
    component: VehicleDetail,
    props: (route) => ({
      ymms_desc: route.params.ymms_desc,
      lat: route.query.lat,
      lng: route.query.lng,
      direct: route.query.direct === "true",
    }),
    meta: {
      sitemap: {
        slugs: async () => await ElectricDriverApi.getDetailSlugs(),
      },
    },
  },
  {
    path: "/Incentives/:state?",
    name: "incentives",
    component: Incentives,
    props: (route) => ({
      state: route.params.state,
    }),
    meta: {
      sitemap: {
        slugs: () => 
          Constants.states.map((state) => ({
            state: state.abbr,
          }))
      },
    },
  },
  {
    path: '/ChargerFinder',
    name: 'chargerFinder',
    component: ChargerFinder,
    props: (route) => {
      return { ...route.query };
    },
    meta: {
      sitemap: {
        slugs: () => {

        }
      }
    }
  },
  {
    path: "/About",
    name: "about",
    component: AboutUs,
  },
  {
    path: "/Terms",
    name: "terms",
    component: TermsOfUse,
  },
  {
    path: "/PrivacyPolicy",
    name: "privacy",
    component: PrivacyPolicy,
  },
  {
    path: "/CookiePolicy",
    name: "cookies",
    component: CookiePolicy,
  },
  {
    path: "/AffiliateDisclaimer",
    name: "affiliate_disclaimer",
    component: AffiliateDisclaimer,
  },
  {
    path: "*",
    redirect: "/",
  },
];
