export default {
  toCurrency,
  pluralize,
  toNumber,
  toPercent,
  toOrdinal,
  methods: {
    toCurrency,
    pluralize,
    toNumber,
    toPercent,
    toOrdinal,
  },
};

function toCurrency(val, decimal = 0) {
  if (isNaN(val)) return val;
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: decimal,
    maximumFractionDigits: decimal,
  });
  var result = formatter.format(val);
  return result;
}

function pluralize(val, label, pluralOverride) {
  if (val !== undefined && val !== null && val == 1) return label;
  return pluralOverride || label + "s";
}

function toNumber(val) {
  return Number(val).toLocaleString();
}

function toPercent(val, decimals = 0) {
  return Math.round(val * 100, decimals) + "%";
}

function toOrdinal(n) {
  var s = ["th", "st", "nd", "rd"];
  var v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
}
